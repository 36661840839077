import { render, staticRenderFns } from "./ActivityModal.vue?vue&type=template&id=b8853cca&scoped=true&"
import script from "./ActivityModal.vue?vue&type=script&lang=js&"
export * from "./ActivityModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8853cca",
  null
  
)

export default component.exports